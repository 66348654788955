import { useCallback } from "react";

import { useGetUserProperty, useSetUserProperty } from "../services/jira/user-properties";
import { JiraApiError } from "../services/jira-api";

const CHANGELOG_NUMBER_APP = 1;
const CHANGELOG_DATE_LAST_UPDATED = "2024-04-22T00:00:00Z";
const THREE_MONTHS = 3 * 30 * 24 * 60 * 60 * 1000;

let changelogNumberUser: number | undefined;

export const useShouldShowChangelog = () => {
  const getUserProperty = useGetUserProperty();

  const getShouldShowChangelog = useCallback(async () => {
    if (changelogNumberUser === undefined) {
      try {
        changelogNumberUser = await getUserProperty<number>("planningPoker.changelogNumber");
      } catch (err) {
        const jiraApiError = err as JiraApiError;

        if (jiraApiError.status !== 404) {
          console.error(jiraApiError);
          return false;
        }

        changelogNumberUser = -1;
      }
    }

    const userMissingChangelogUpdates = changelogNumberUser < CHANGELOG_NUMBER_APP;
    const isChangelogUpdateRecent = Date.now() - new Date(CHANGELOG_DATE_LAST_UPDATED).getTime() < THREE_MONTHS;

    return userMissingChangelogUpdates && isChangelogUpdateRecent;
  }, [getUserProperty]);

  return getShouldShowChangelog;
};

export const useUpdateChangelogNumberUser = () => {
  const setUserProperty = useSetUserProperty();

  const updateChangelogNumberUser = useCallback(() => {
    if (changelogNumberUser === CHANGELOG_NUMBER_APP) return;

    setUserProperty("planningPoker.changelogNumber", CHANGELOG_NUMBER_APP).catch((jiraApiError) =>
      console.error(jiraApiError),
    );

    // Prevent constantly showing changelog dialog
    changelogNumberUser = CHANGELOG_NUMBER_APP;
  }, [setUserProperty]);

  return updateChangelogNumberUser;
};
