import React, { useCallback, useMemo, useState } from "react";

import Button from "@atlaskit/button";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import { CheckboxOption, OptionType, PopupSelect } from "@atlaskit/select";

import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { JIRA_FIELD_SCHEMA_ITEMS, JIRA_FIELD_TYPE, JiraField } from "../../types";
import { isStringTypeSupported, SPRINT_FIELD_SCHEMA } from "../../utils";

interface ColumnsSelectorProps {
  excludedColumns: string[];
  onSelectedColumnsChange: (values: string[]) => void;
  selectedColumnIds: string[];
}

const isFieldIdSupported = (field: JiraField) => field.id === "parent";
const SUPPORTED_COLUMN_TYPES: JIRA_FIELD_TYPE[] = [
  JIRA_FIELD_TYPE.STRING,
  JIRA_FIELD_TYPE.NUMBER,
  JIRA_FIELD_TYPE.OPTION,
  JIRA_FIELD_TYPE.USER,
  JIRA_FIELD_TYPE.ARRAY,
  JIRA_FIELD_TYPE.DATE,
  JIRA_FIELD_TYPE.DATETIME,
  JIRA_FIELD_TYPE.TIME_TRACKING,
];

const isTypeSupported = (field: JiraField, excludedColumns: string[]) =>
  field.schema?.type && SUPPORTED_COLUMN_TYPES.includes(field.schema.type) && !excludedColumns.includes(field.id);

const SUPPORTED_ARRAY_TYPES: JIRA_FIELD_SCHEMA_ITEMS[] = [
  JIRA_FIELD_SCHEMA_ITEMS.STRING,
  JIRA_FIELD_SCHEMA_ITEMS.VERSION,
  JIRA_FIELD_SCHEMA_ITEMS.JSON,
  JIRA_FIELD_SCHEMA_ITEMS.COMPONENT,
];

const isArrayTypeSupported = (field: JiraField) =>
  !(
    field.schema?.type === JIRA_FIELD_TYPE.ARRAY &&
    (!field.schema?.items || !SUPPORTED_ARRAY_TYPES.includes(field.schema.items))
  );

const isArrayJsonSchemaSupported = (field: JiraField) =>
  !(
    field.schema?.type === JIRA_FIELD_TYPE.ARRAY &&
    field.schema.items === JIRA_FIELD_SCHEMA_ITEMS.JSON &&
    field.schema.custom !== SPRINT_FIELD_SCHEMA
  );

export const isFieldTypeSupported = (field: JiraField, excludedColumns: string[]) =>
  isTypeSupported(field, excludedColumns) &&
  isArrayTypeSupported(field) &&
  isStringTypeSupported(field) &&
  isArrayJsonSchemaSupported(field);

type MultiValue<Option> = readonly Option[];

export function ColumnsSelector({
  excludedColumns,
  selectedColumnIds,
  onSelectedColumnsChange,
}: Readonly<ColumnsSelectorProps>) {
  const { fields } = useJiraDataStore();
  const [columns, setColumns] = useState<string[]>(selectedColumnIds);

  const columnsOptions = useMemo(
    () =>
      fields
        ?.filter((field) => isFieldIdSupported(field) || isFieldTypeSupported(field, excludedColumns))
        .map((field) => ({ value: field.id, label: field.name })),
    [excludedColumns, fields],
  );

  const selectedColumns = useMemo(
    () => columnsOptions?.filter((column) => columns.includes(column.value)),
    [columnsOptions, columns],
  );

  const onChange = useCallback(
    (options: MultiValue<OptionType>) => {
      const ids = options.map((o) => o.value as string);
      setColumns(ids);
      setTimeout(() => {
        onSelectedColumnsChange(ids);
      }, 0);
    },
    [onSelectedColumnsChange],
  );

  return (
    <PopupSelect
      components={{ Option: CheckboxOption }}
      value={selectedColumns}
      options={columnsOptions}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      target={({ isOpen, ...triggerProps }) => (
        <Button {...triggerProps} isSelected={isOpen} iconAfter={<ChevronDownIcon label="" />}>
          Columns
        </Button>
      )}
      onChange={onChange}
    />
  );
}
