import React, { useMemo } from "react";

import { Box } from "@fuegokit/react";

import "./Description.css";

import { useApplicationContext } from "../providers/ApplicationContextProvider";
import { Attachment, JiraIssue } from "../types";
import { parseHtmlFromString } from "../utils/jira-renderer";
import { CollapsiblePanel } from "./game/IssueDetails/CollapsiblePanel";

export function JiraHtmlRenderer({ text, attachments }: { text: string; attachments: Attachment[] }) {
  const { hostBaseUrl } = useApplicationContext();
  const html = useMemo(
    () =>
      parseHtmlFromString({
        text,
        baseURL: hostBaseUrl,
        attachments,
      }),
    [text, hostBaseUrl, attachments],
  );
  return (
    <Box className="renderer">
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </Box>
  );
}

export function Description({
  issue,
  isExpanded,
  toggle,
}: {
  issue: JiraIssue;
  isExpanded: boolean;
  toggle: VoidFunction;
}) {
  const attachments = useMemo(() => issue.fields.attachment || [], [issue.fields.attachment]);
  return (
    <CollapsiblePanel
      isExpanded={isExpanded}
      toggle={toggle}
      title="Description"
      secondaryText={issue.fields.description}
    >
      {issue.fields.description ? (
        <Box py="12px" px="16px">
          <JiraHtmlRenderer text={issue.renderedFields.description} attachments={attachments} />
        </Box>
      ) : null}
    </CollapsiblePanel>
  );
}
